<template>
  <div class="vx-row mb-12">
    <div class="core vx-col md:w-1/1 w-full mb-base">
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>Group</vs-th>
          <vs-th>Sequence</vs-th>
          <vs-th>Last Action</vs-th>
          <vs-th>Is Active</vs-th>
          <vs-th>Status</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.name }}</vs-td>
            <vs-td>{{ tr.group }}</vs-td>
            <vs-td>{{ tr.sequence }}</vs-td>
            <vs-td>{{
              dateFormatWithoutTimezone(tr.last_action, "DD-MM-YYYY HH:mm:ss")
            }}</vs-td>
            <vs-td>
              <vs-switch
                v-model="tr.is_active"
                color="success"
                :disabled="tr.is_active"
              />
            </vs-td>
            <vs-td>{{ tr.status }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </div>
</template>

<script>
import { dateFormatWithoutTimezone } from "../../../../utils/common";

export default {
  data() {
    return {
      baseUrl: "api/sfa/v1/monitoring-dashboard/dashboard-etl/sync-control",
      selected_rows: [],
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        is_waiting_list: true,
        status: 1,
        rowNumberStart: 1,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    dateFormatWithoutTimezone,
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData(this.table);
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
          },
        })
        .then((resp) => {
          if (resp.status == "OK") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.rowNumberStart = resp.data.row_number_start;
            if (resp.data.records == null) {
              this.table.data = [];
            } else {
              this.table.data = resp.data.records;
            }
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
            });
          }
        });
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
